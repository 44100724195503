import { Grid, Pagination as MuiPagination, Button } from "@mui/material";
import { useState, useEffect } from "react";
import Select from "react-select";
import { Form } from "react-bootstrap";
import { Link } from "react-router-dom";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import ScaleLoader from "react-spinners/ScaleLoader";
import "../Student/css/MatchedJobs.scss";
import { MONTH_DETAILS } from "../data";
import { IoIosArrowForward } from "react-icons/io";
import { IoIosArrowBack } from "react-icons/io";
import { loadUser } from "../../actions/userActions";
import { useDispatch } from "react-redux";
import Pagination from "./Pagination";

function EmployerDetail() {
  const dispatch = useDispatch();
  const itemsPerPage = 15; // Set the number of items to display per page
  const [currentPage, setCurrentPage] = useState(1);
  const [teamData, setTeamData] = useState("");
  const [search, setSearch] = useState("");
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  const handleChange = (e) => {
    setSearch({ ...search, [e.target.name]: e.target.value });
  };

  useEffect(() => {
    dispatch(loadUser());
  }, []);

  const getData = async () => {
    setLoading(true);
    try {
      const { data } = await axios.post("/api/user/getemployerdata", {
        search,
      });
      console.log(data, "data");
      // Iterate over each item in the data array
      data.forEach((item) => {
        // Find the latest job entry date for the current item
        const latestJob =
          item.jobs.length > 0
            ? item.jobs.reduce((latest, current) => {
                return latest.datePosted > current.datePosted
                  ? latest
                  : current;
              })
            : null;

        // Add a new property 'latestJobDate' to the item with the latest job entry date
        item.latestJobDate = latestJob ? latestJob.datePosted : null;
      });

      // Sort the data based on the 'latestJobDate' property
      data.sort((a, b) => {
        return new Date(b.latestJobDate) - new Date(a.latestJobDate);
      });

      // Set the sorted data into TeamData state
      setTeamData(data);
      setLoading(false);
    } catch (error) {
      console.log("Server is not running...", error);
      setLoading(false);
    }
  };

  useEffect(() => {
    getData();
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    getData();
    window.scrollTo(0, 0);
  }, [currentPage]);

  useEffect(() => {
    getData();
  }, [search]);

  // const filteredTeamData = teamData && teamData.reduce((acc, current) => {
  //   // Check if the organizationName already exists in the accumulator
  //   const existingOrg = acc.find(item => item.organisationURL === current.organisationURL);

  //   // If not found, add the current object to the accumulator
  //   if (!existingOrg) {
  //     acc.push(current);
  //   }

  //   // Return the accumulator for the next iteration
  //   return acc;
  // }, []);
  const filteredTeamData =
    teamData &&
    teamData.reduce((acc, current) => {
      // Extract domain name from the organisationURL
      const domain = current.organisationURL.match(
        /^(?:https?:\/\/)?(?:www\.)?([^\/\?]+)/i
      )[1];

      // Check if the domain already exists in the accumulator
      const existingOrg = acc.find((item) => {
        const existingDomain = item.organisationURL.match(
          /^(?:https?:\/\/)?(?:www\.)?([^\/\?]+)/i
        )[1];
        return existingDomain === domain;
      });

      // If not found, add the current object to the accumulator
      if (!existingOrg) {
        acc.push(current);
      }

      // Return the accumulator for the next iteration
      return acc;
    }, []);
  // Calculate the total number of pages
  // const pageCount = Math.ceil(teamData.length / itemsPerPage);
  const pageCount = Math.ceil(filteredTeamData.length / itemsPerPage);
  // teamData && teamData?.sort((a,b)=>new Date(b.datePosted) - new Date(a.datePosted))
  filteredTeamData &&
    filteredTeamData?.sort(
      (a, b) => new Date(b.datePosted) - new Date(a.datePosted)
    );
  // Get the current page data
  // const currentData = teamData?.slice(
  // (currentPage - 1) * itemsPerPage,
  // currentPage * itemsPerPage
  // );
  const currentData = filteredTeamData?.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  );

  // Handle page change
  const handlePageChange = (value) => {
    setCurrentPage(value);
  };

  console.log(currentData, "currentData");
  console.log(teamData, "teamData");
  console.log(filteredTeamData, "filteredTeamData");
  return (
    <>
      <div
        className="py-4"
        style={{ backgroundColor: "rgba(224, 220, 243, 0.212)" }}
      >
        <Grid
          item
          container
          md={12}
          xs={12}
          lg={12}
          justifyContent="center"
          alignItems="center"
          mt={5}
          mb={5}
        >
          <h2
            className="borderremove"
            style={{
              backgroundColor: "white",
              borderRadius: "10px",
              paddingLeft: "10%",
              paddingRight: "10%",
              paddingTop: "1%",
              paddingBottom: "1%",
            }}
          >
            Employer Details
          </h2>
        </Grid>

        <div
          className="borderremove"
          style={{
            backgroundColor: "white",
            borderRadius: "10px",
            marginLeft: "auto",
            marginRight: "auto",
            // marginBottom: "50px",
            maxWidth: "1200px",
            width: "90%",
            overflow: "auto",
          }}
        >
          <div style={{ width: "100%", minWidth: "700px" }}>
            <Grid
              container
              direction="row"
              justifyContent="center"
              alignItems="center"
              sx={{
                borderRadius: "10px 10px  0  0 ",
                fontSize: { md: "18px", sm: "12px", xs: "8px" },
                fontWeight: "500",
                color: "#FFF",
                paddingTop: "1%",
                background: "#4B92C8",
              }}
            >
              <Grid
                item
                xs={3}
                md={3}
                container
                justifyContent="center"
                alignItems="center"
              >
                <p>Company Name</p>
              </Grid>
              <Grid
                item
                xs={3}
                md={3}
                container
                justifyContent="center"
                alignItems="center"
              >
                <p>Hiring Since</p>
              </Grid>
              <Grid
                item
                xs={3}
                md={3}
                container
                justifyContent="center"
                alignItems="center"
              >
                <p>Office Location</p>
              </Grid>
              <Grid
                item
                xs={3}
                md={3}
                container
                justifyContent="center"
                alignItems="center"
              >
                <p>Actions</p>
              </Grid>
            </Grid>

            {/* <Grid item xs={12} md={12} >
            <hr style={{ border: '1px solid rgba(175, 175, 175, 0.9)', width: '100%', height: '1px', position: 'relative', bottom: '7px' }} />
        </Grid> */}

            <Grid
              container
              direction="row"
              sx={{
                fontSize: { md: "16px", xs: "12px" },
                fontWeight: "500",
                color: "#000",
                paddingBottom: "20px",
                paddingTop: "20px",
              }}
            >
              <Grid
                item
                xs={3}
                md={3}
                container
                justifyContent="center"
                alignItems="center"
              >
                <Form.Control
                  placeholder="Search Name"
                  onChange={handleChange}
                  name="name"
                  value={search.name}
                  style={{
                    minWidth: "110px",
                    marginLeft: "20px",
                    width: "60%",
                    height: "40px",
                    borderRadius: "5px",
                    border: "1px solid #dddddd",
                    fontSize: "15px",
                  }}
                />
                {/* <input type="text" placeholder="Search" style={{ width: '60%', height: '40px', borderRadius: '5px', border: '1px solid #dddddd', textAlign: 'center', fontSize: '16px' }} /> */}
              </Grid>
              <Grid
                item
                xs={3}
                md={3}
                container
                justifyContent="center"
                alignItems="center"
              >
                {/* <Form.Control type="number" placeholder="Search No. of Job" onChange={handleChange} name = 'jobPosted' value = {search.jobPosted} style={{ width: '60%', height: '40px', borderRadius: '5px', border: '1px solid #dddddd', fontSize: "15px"}} /> */}
              </Grid>
              <Grid
                item
                xs={3}
                md={3}
                container
                justifyContent="center"
                alignItems="center"
              >
                <Form.Control
                  placeholder="Search Location"
                  onChange={handleChange}
                  name="location"
                  value={search.location}
                  style={{
                    minWidth: "130px",
                    marginLeft: "20px",
                    width: "60%",
                    height: "40px",
                    borderRadius: "5px",
                    border: "1px solid #dddddd",
                    fontSize: "15px",
                  }}
                />
              </Grid>
              <Grid
                item
                xs={3}
                md={3}
                container
                justifyContent="center"
                alignItems="center"
              >
                {/*<Button onClick={handleSearch} className="py-3 px-5" style={{fontSize: '13px'}} variant="contained" sx={{textTransform: 'none', textDecoration: 'none', background: '#4B92C8','&:hover': {background: 'none',color: '#4B92C8'}, 
              }}>Search</Button>*/}
              </Grid>
            </Grid>
            {loading ? (
              <>
                <Grid item xs={12} md={12}>
                  <hr
                    style={{
                      border: "1px solid #dadada",
                      width: "100%",
                      height: "1px",
                      position: "relative",
                      bottom: "7px",
                    }}
                  />
                </Grid>

                <Grid
                  item
                  xs={12}
                  md={12}
                  container
                  justifyContent="start"
                  alignItems="center"
                  style={{ paddingLeft: "5%" }}
                >
                  <div className=" mx-auto" style={{ marginBottom: "20px" }}>
                    <ScaleLoader
                      color={"#4B92C8"}
                      loading={true}
                      size={15}
                      aria-label="Loading Spinner"
                      data-testid="loader"
                    />
                  </div>
                </Grid>
              </>
            ) : (
              <>
                <Grid item xs={12} md={12}>
                  <hr
                    style={{
                      border: "1px solid #dadada",
                      width: "100%",
                      height: "1px",
                      position: "relative",
                      bottom: "7px",
                    }}
                  />
                </Grid>

                {/* Data rows */}
                {currentData && currentData.length > 0 ? (
                  <>
                    {currentData.map((item, index) => (
                      <div key={index}>
                        <Grid
                          container
                          justifyContent="center"
                          alignItems="center"
                          direction="row"
                          sx={{
                            fontSize: { md: "16px", xs: "10px" },
                            fontWeight: "400",
                            color: "#000",
                          }}
                        >
                          <Grid
                            item
                            xs={3}
                            md={3}
                            container
                            justifyContent="start"
                            alignItems="center"
                            style={{ paddingLeft: "5%" }}
                          >
                            <p style={{ paddingLeft: "10px" }}>
                              {item.organisationName}
                            </p>
                          </Grid>
                          <Grid
                            item
                            xs={3}
                            md={3}
                            container
                            justifyContent="center"
                            alignItems="center"
                          >
                            <p>
                              {MONTH_DETAILS[
                                parseInt(item.dataAdded.slice(5, 7), 10)
                              ] +
                                "' " +
                                item.dataAdded.slice(0, 4)}
                            </p>
                          </Grid>
                          <Grid
                            item
                            xs={3}
                            md={3}
                            container
                            justifyContent="start"
                            alignItems="center"
                            style={{ paddingLeft: "5%" }}
                          >
                            <p style={{ paddingLeft: "10px" }}>{item.city}</p>
                          </Grid>
                          <Grid
                            item
                            xs={3}
                            md={3}
                            container
                            justifyContent="center"
                            alignItems="center"
                          >
                            {/* <TeamDelete /> */}
                            <Button
                              style={{
                                fontSize: "12px",
                                fontWeight: "bold",
                                marginTop: index === 0 ? "-5px" : "0px",
                              }}
                              onClick={() =>
                                navigate("/company-detail", { state: item })
                              }
                              variant="contained"
                              sx={{
                                textTransform: "none",
                                textDecoration: "none",
                                background: "#4B92C8",
                                "&:hover": {
                                  background: "none",
                                  color: "#4B92C8",
                                },
                              }}
                            >
                              View More
                            </Button>
                          </Grid>
                        </Grid>
                        <Grid item xs={12} md={12}>
                          <hr
                            style={{
                              border: "1px solid #dadada",
                              width: "100%",
                              height: "1px",
                            }}
                          />
                        </Grid>
                      </div>
                    ))}
                  </>
                ) : (
                  <></>
                )}

                {/* Pagination component */}
                <Grid
                  item
                  xs={12}
                  md={12}
                  container
                  justifyContent="end"
                  alignItems="end"
                  sx={{ marginBottom: "1%", paddingRight: "5%" }}
                >
                  {pageCount > 1 ? (
                    <>
                      {/* 
          <MuiPagination
            count={pageCount}
            page={currentPage}
            onChange={handlePageChange}
            color="primary"
            size="large"
            //   shape="rounded"
            variant="outlined"
            className="mb-4"
          />
          */}
                    </>
                  ) : (
                    <>
                      <div style={{ marginTop: "30px" }}></div>
                    </>
                  )}
                </Grid>
              </>
            )}
          </div>
        </div>
        {pageCount > 1 ? (
          <>
            {/* <div className="pagination" style={{ marginTop: "-20px" }}>
                        <p onClick={() => handlePageChange(currentPage - 1)} disabled={currentPage === 1}>
                          <IoIosArrowBack className={currentPage === 1 ? "paginationBtndisabled" : "paginationBtn" } style={{ marginTop: "6px" }} />
                        </p>
                        <span style={{ fontSize: "16px" }}>
                          {currentPage} / {pageCount}
                        </span>
                        <p onClick={() => handlePageChange(currentPage + 1)} disabled={currentPage === pageCount}>
                          <IoIosArrowForward className= {currentPage === pageCount ? "paginationBtndisabled" : "paginationBtn" } style={{ marginTop: "6px" }} />
                        </p>
                      </div> */}

            {/* <div className="pagination" style={{ marginTop: "-20px" }}>
              <p
                onClick={() => currentPage > 1 && handlePageChange(currentPage - 1)}
                className={currentPage === 1 ? "paginationBtndisabled" : "paginationBtn"}
              >
                <IoIosArrowBack style={{ marginTop: "6px" }} />
              </p>
              <span style={{ fontSize: "16px" }}>
                {currentPage} / {pageCount}
              </span>
              <p
                onClick={() => currentPage < pageCount && handlePageChange(currentPage + 1)}
                className={currentPage === pageCount ? "paginationBtndisabled" : "paginationBtn"}
              >
                <IoIosArrowForward style={{ marginTop: "6px" }} />
              </p>
            </div> */}
            <div
              className=""
              style={{
                
                
                marginLeft: "auto",
                marginRight: "auto",
                // marginBottom: "50px",
                maxWidth: "1200px",
                width: "90%",
                overflow: "auto",
                display:"flex",
                justifyContent:"flex-end"
              }}
            >
              <Pagination
                totalPages={pageCount}
                currentPage={currentPage}
                onPageChange={setCurrentPage}
              />
            </div>
          </>
        ) : (
          <></>
        )}
      </div>
    </>
  );
}

export default EmployerDetail;
