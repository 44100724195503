import React, { useEffect, useRef, useState } from "react";
import { FiGithub } from "react-icons/fi";
import { FaLink } from "react-icons/fa";
import { FaBlog } from "react-icons/fa";
import { CiCircleQuestion, CiStar } from "react-icons/ci";
import { FaStar } from "react-icons/fa";
import { TbNotes } from "react-icons/tb";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { IoCaretDownSharp, IoChatboxEllipsesOutline, IoChatboxOutline } from "react-icons/io5";
import { BiMessageSquareDetail } from "react-icons/bi";
import { BsChatLeftDots, BsPersonCheck } from "react-icons/bs";
import axios from "axios";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { FaRegFolder } from "react-icons/fa";
import { TiFlashOutline } from "react-icons/ti";
import SimpleDialog from "./Employertemplates/ChatDialog";
import { Tooltip } from "@material-tailwind/react";
import { getAppliedBefore, getMonthDuration, getMonthYear, getTotalWorkExperience } from "./utils/timefunctions";
import { Requesttype } from "./utils/constants";
import * as Papa from "papaparse";


const ApplicationsAll = ({
  setCounts,
  counts,
  filterInput,
  setFilterInput,
  initial,
  filterTrigger,
  setFilterTrigger,
  searchApplicant,
  setSearchApplicant,
  searchTrigger
}) => {
  const [showFull, setShowFull] = useState([]);
  const [All, setAll] = useState(Array.from({ length: 10 }, () => true));
  const [selectedCandidates, setSelectedCandidates] = useState(Array.from({ length: 10 }, () => false));
  const [selectAllToggler, setSelectAllToggler] = useState(false);
  const [showOptions, setShowOptions] = useState(false);
  const [openPopup, setOpenPopup] = useState(false);
  const params = useParams();
  // const { state } = useLocation();
  const [state,setState] = useState(null)
  const location = useLocation();
  const params2 = new URLSearchParams(location.search);
  const encodedData = params2.get('data');
  const state2 = encodedData ? JSON.parse(decodeURIComponent(encodedData)) : null;
  const [showAllSkills,setShowAllSkills] =useState(false);
  console.log(state,"all sss")
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`/api/employer/getJobInformationInState?id=${state2}`);
        setState(response.data);
      } catch (error) {
        console.error('Error fetching job data:', error);
      }
    };

    if (state2) {
      fetchData();
    }
  }, [state2]);
  const openReviewPageInNewTab = (state) => {
    window.open(`/review/${state._id}`);
  };

  const getJobApplications = async (data) => {
    const reqBody = {
      tabs: Requesttype[data],
      filterInput: filterInput,
    };

    try {
      const { data } = await axios.post(
        "/api/employer/filterAppliedJob",
        { reqBody },
        {
          headers: {
            "Content-Type": "application/json",
            token: localStorage.getItem("token"),
          },
        }
      );

      setCounts(data?.RESULT_DATA?.ApplicationCounts);
      setAll(data?.RESULT_DATA?.AppliedJobData);
      setSelectedCandidates(Array.from({ length: data?.RESULT_DATA?.AppliedJobData?.length }, () => false));
      setShowFull(Array.from({ length: data?.RESULT_DATA?.AppliedJobData?.length }, () => false));
      // console.log(data?.RESULT_DATA?.AppliedJobData);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    
     getJobApplications(params?.type);
    
  },[searchTrigger]);
  console.log(All,"all")
  const handleUpdateStatus = async (status, i) => {
    var reqBody = {
      function: status,
      data: All.filter((items, index) => selectedCandidates[index]).map((items) => items._id),
    };

    if (i != -1) {
      reqBody.data = [All[i]._id];
    }

    console.log(reqBody);
    try {
      await axios.post(
        "/api/employer/changeStatusAppliedJob",
        { reqBody },
        {
          headers: {
            "Content-Type": "application/json",
            token: localStorage.getItem("token"),
          },
        }
      );

      toast.success("Status changed successfully");
      setTimeout(() => {
        window.location.reload();
      }, 2000);
    } catch (error) {
      toast.error("Something went wrong!!");
      console.log(error);
    }
  };

  const handleExportData = async () => {
    try {
      const response = await axios.post(
        "/api/employer/exporttoexcel",
        { selectedIds: All.filter((items, index) => selectedCandidates[index]).map((items) => items._id) },
        {
          headers: {
            "Content-Type": "application/json",
            token: localStorage.getItem("token"),
          },
        }
      );

      const data = response.data.ResultData;

      // Flatten the nested structure
      const flattenedData = data.map((entry) => {
        const { _id, coverLetter, availability, assessment, status, appliedDate, job, user, employer, resume } = entry;

        const { PersonalDetails, Education, Job, Internship, Training, Academics, Skills } = resume;

        const { firstName, lastName, address, phone, email, _id: personalDetailsId } = PersonalDetails || {};

        const educationDetails = Education && Education.length > 0 ? Education[0] : {};

        const skillsArray = Skills ? Skills.map((skill) => skill.name) : [];

        return {
          _id,
          coverLetter,
          availability,
          assessment,
          status,
          appliedDate,
          job,
          user,
          employer,
          firstName,
          lastName,
          address,
          phone,
          email,
          personalDetailsId,
          school: educationDetails.school,
          degree: educationDetails.degree,
          startDate: educationDetails.startDate,
          endDate: educationDetails.endDate,
          performanceScale: educationDetails.performanceScale,
          performance: educationDetails.performance,
          skills: skillsArray.join(", "), // Convert skills array to a comma-separated string
        };
      });

      // Convert flattened data to CSV format using Papaparse
      const csvData = Papa.unparse(flattenedData);

      // Create a Blob with the CSV data
      const blob = new Blob([csvData], { type: "text/csv" });

      // Create a download link and trigger the download
      const link = document.createElement("a");
      link.href = URL.createObjectURL(blob);
      link.download = `ExportedData_${new Date().toISOString()}.csv`;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);

      toast.success("File exported");
    } catch (error) {
      toast.error("Something went wrong!!");
      console.error(error);
    }
  };

  useEffect(() => {
    setSearchApplicant("");
    setFilterInput(initial);
    setFilterTrigger((prev) => prev + 1);
    setSelectedCandidates((prev) => prev.map((i) => false));
    setSelectAllToggler(false);
    window.scrollTo(0, 0);
  }, [params]);

  useEffect(() => {
    setSearchApplicant("");
    getJobApplications(params?.type);
  }, [filterTrigger]);

  useEffect(() => {
    var count = 0;
    selectedCandidates.forEach((candidate) => (count += candidate === true));
    setShowOptions(count > 0);
  }, [selectedCandidates]);

  const truncate = (text, index) => {
    if (showFull[index]) {
      return text;
    } else {
      return text?.substring(0, 150) + "...";
    }
  };

  const handleSelectAll = (e) => {
    const ok = e.target.checked;
    setSelectAllToggler(ok);
    setShowOptions(ok);
    setSelectedCandidates((prev) => prev.map((i) => ok));
  };

  // const handleSearch = (i) => {
  //   return (i.firstName + " " + i.lastName).toLowerCase().includes(searchApplicant.toLowerCase());
  // };
  // const handleSearch = (i) => {
  //   return (
  //     (i.firstName + " " + i.lastName).toLowerCase().includes(searchApplicant.toLowerCase()) ||
  //     i.email.toLowerCase().includes(searchApplicant.toLowerCase())
  //   );
  // };
  const handleSearch = (i) => {
    const fullName = (i.firstName.trim() + " " + i.lastName.trim()).toLowerCase();
    const searchInput = searchApplicant.trim().toLowerCase();
    return (
      fullName.includes(searchInput) ||
      i.email.trim().toLowerCase().includes(searchInput)
    );
  };
  All && All?.sort((a, b) => new Date(b.appliedDate) - new Date(a.appliedDate));
  const navigate = useNavigate();
  const handleChatClick = async (userId, employerId, appliedJobId,mainId, navigate) => {
   
    try {
      const response = await axios.post('/api/employer/sendMessagebyclickingchaticon', {
        sender: employerId,
        receiver: [[mainId, userId]],
        appliedJobId: appliedJobId,
        // message: '' // You can customize the message content
      },{
        headers: {
          "Content-Type": "application/json",
          token: localStorage.getItem("token"),
        },
      });
  
      console.log(response.data.data,"wff")
      if (response.status === 200) {
        const roomId = response.data.data;
        navigate('/messageEmployer' , { state: roomId });
      }
    } catch (error) {
      console.error('Error creating chat room:', error);
      // Handle error (e.g., show a notification to the user)
    }
  };
  return (
    <>

      <div className= "applicationReceivedAll d-flex flex-column gap-5 px-5 py-3 bg-white">
        {params?.type == "All" && (
          <div className="h4" style={{ color: "#484848", fontWeight: "600" }}>
            Showing {All?.filter((i) => searchApplicant == "" || handleSearch(i.resume.PersonalDetails)).length || 0} results out of{" "}
            {counts?.ApplicationReceivedCount} applications
          </div>
        )}

        {params?.type == "Shortlisted" && (
          <div className="banner">
            <span> To ensure your rest of the hiring experience is smooth, may we recommend that you -</span>
            <ul>
              <li className="mb-2">
                Always interview the applicants before finalizing - Helps you understand the candidates better: their availability for the internship,
                their understanding of the role, their expectations & communication skills.
              </li>
              <li>Always extend a formal offer letter - And ask them to convey acceptance before starting.</li>
            </ul>
          </div>
        )}

        <div className="d-flex gap-3 flex-wrap align-items-center">
          <input onChange={handleSelectAll} type="checkbox" checked={selectAllToggler} style={{ width: "16px", height: "16px", outline: "none" }} />
          <div style={{ fontSize: "14px", color: "#484848" }}>Select all</div>
          {showOptions && (
            <>
              {/* <button className="shortcut"> Send assignment</button> */}
              {params?.type != "Ninterested" ? (
                <>
                  <button onClick={() => setOpenPopup(!openPopup)} className="shortcut">
                    Send message
                  </button>
                  <button onClick={() => handleExportData()} className="shortcut">
                    Export to Excel
                  </button>
                  {params?.type !== "Hired" && (
                    <>
                      {params?.type !== "Shortlisted" && (
                        <button onClick={() => handleUpdateStatus("Shortlisted", -1)} className="shortcut">
                          Shortlist
                        </button>
                      )}
                      <button onClick={() => handleUpdateStatus("Hired", -1)} className="shortcut">
                        Hire
                      </button>
                      <button onClick={() => handleUpdateStatus("NotIntrested", -1)} className="shortcut">
                        Not interested
                      </button>
                    </>
                  )}
                </>
              ) : (
                <>
                  <span className="px-3 py-2 mr-2" style={{ backgroundColor: "#eee", color: "#333", fontSize: "14px" }}>
                    Move to:
                  </span>
                  <button onClick={() => handleUpdateStatus("Application_Sent", -1)} className="shortcut">
                    Application received
                  </button>
                  <button onClick={() => handleUpdateStatus("Shortlisted", -1)} className="shortcut">
                    Shortlisted
                  </button>
                </>
              )}
            </>
          )}
        </div>
      </div>

      <div className="list-container">
        {All?.length == 0 ? (
          <div style={{ fontSize: "14px", color: "#e5330e", textAlign: "center" }}>
            No applications to show. Please reach out to us in case of any queries.
          </div>
        ) : (
          <>
            {All?.filter((x) => searchApplicant == "" || handleSearch(x?.resume?.PersonalDetails))?.map((x, index) => (
              <div key={index} className="candidate-container">
                <div className="d-flex align-items-center justify-content-between w-100 px-4 py-3">
               {/*   <span style={{ color: "#008bdc", fontSize: "14px", fontWeight: "500", cursor: "pointer" }}>Know more</span>*/}
               <span style={{ color: "#008bdc", fontSize: "14px", fontWeight: "500", cursor: "pointer" }}></span>
                  <span style={{ color: "#8a8a8a", fontSize: "12px", fontWeight: "500" }}>{getAppliedBefore(x?.appliedDate)}</span>
                </div>
                <div style={{ width: "100%", height: "1px", backgroundColor: "#ddd" }}></div>
                <div className="p-3 p-md-4 d-flex flex-column gap-1">
                  <div className="d-flex gap-3 align-items-start">
                    <div>
                      <input
                        style={{ width: "16px", height: "16px", outline: "none" }}
                        checked={selectedCandidates[index]}
                        onChange={(e) => {
                          
                          if (selectedCandidates[index]) {
                            setSelectAllToggler(false);
                          }
                          setSelectedCandidates((prev) => prev.map((i, ix) => (ix === index ? !i : i)));
                        }}
                        type="checkbox"
                      />
                    </div>
                    <div className="candidate-details">
                      <h2 className="mb-4 text-uppercase">
                        {x?.resume?.PersonalDetails?.firstName} {x?.resume?.PersonalDetails?.lastName}
                      </h2>
                      <h5 className="mb-5 d-flex flex-wrap align-items-center gap-3" style={{ color: "#666", fontSize: "12px" }}>
                        {x?.resume?.PersonalDetails?.address} (Open to relocate) <Circle /> Total work experience:{" "}
                        {getTotalWorkExperience(x?.resume?.Internship, x?.resume?.Job)}
                        <Info text={<span style={{ fontSize: "14px" }}>Includes both Internship and Job experience</span>} />
                      </h5>

                      <div className="w-100 d-flex flex-column gap-4">
                        {/* Expericne */}
                        {(x.resume?.Job?.length > 0 || x.resume?.Internship?.length > 0) && (
                          <div className="d-flex gap-2 align-items-start">
                            <div className="title">Experience</div>
                            <div className="d-flex flex-column gap-4">
                              {x?.resume?.Internship?.map((intern) => (
                                <div className="description-main">
                                  {intern?.profile} at {intern?.organization} <Circle />
                                  <span className="description">
                                    {getMonthYear(intern?.startDate)} - {intern?.endDate ? getMonthYear(intern?.endDate) : "Present"}
                                    <Circle />
                                    {getMonthDuration(intern?.startDate, intern?.endDate ? intern.endDate : new Date())} (Internship)
                                  </span>
                                </div>
                              ))}

                              {x?.resume?.Job?.slice(0, 2)?.map((intern, id) => (
                                <div className="description-main">
                                  {intern?.profile} at {intern?.organization} <Circle />
                                  <span className="description">
                                    {getMonthYear(intern?.startDate)} - {intern?.endDate ? getMonthYear(intern?.endDate) : "Present"}
                                    <Circle />
                                    {getMonthDuration(intern?.startDate, intern?.endDate ? intern.endDate : new Date())} (Job)
                                  </span>
                                  {id == 1 && x?.resume?.Job?.length > 2 && (
                                    <span className="description-light">+{x?.resume?.Job?.length - 2} more</span>
                                  )}
                                </div>
                              ))}
                            </div>
                          </div>
                        )}

                        {/* Education */}
                        {x.resume?.Education?.length > 0 && (
                          <div className="d-flex gap-2 align-items-start">
                            <div className="title">Education</div>
                            <div>
                              {x?.resume?.Education?.slice(0, 2)?.map((edu, id) => (
                                <>
                                  <div className="description-main">
                                    {edu?.degree} ({new Date(edu?.startDate).getFullYear()} - {new Date(edu?.endDate).getFullYear()})
                                    <Circle />
                                    <span className="description">{edu?.performanceScale} - </span>{" "}
                                    {edu?.performance[0] + (edu?.performanceScale == "Percentage" && "%")}
                                  </div>
                                  <div className="description-main">
                                    <span className="description-light">{edu?.school}</span>
                                    {x.resume?.Education?.length > 2 && id === 1 && (
                                      <span className="description-light ml-5">+{x.resume?.Education?.length - 2} more</span>
                                    )}
                                  </div>
                                </>
                              ))}
                            </div>
                          </div>
                        )}

                        {/* Portfolio */}
                        {x?.resume?.Academics?.length > 0 && (
                          <div className="d-flex gap-2 align-items-start">
                            <div className="title">Portfolio/work samples</div>
                            <div className="description-main" style={{ color: "#008bdc", fontSize: "18px", gap: "28px" }}>
                              {/* <a href="">
                              <FaBlog />
                            </a> */}
                              <a target="__blank" href={x?.resume?.Academics[0]?.link}>
                                <FiGithub />
                              </a>
                              {/* <a href="">
                              <FaLink />
                            </a> */}
                            </div>
                          </div>
                        )}

                        {/* Skills */}
                        {/* {x.resume?.Skills?.length > 0 && (
                          <div className="d-flex gap-2 align-items-start">
                            <div className="title">
                              Skills <Info text="Self-assessed by applicants" />
                            </div>
                            <div className="description-main">
                              {x?.resume?.Skills?.slice(0, 5)?.map((skill, id) => (
                                <>
                                  <Star ok={skill?.level[0] == "B" ? 1 : skill?.level[0] == "I" ? 2 : 3} skill={skill?.name} />
                                  {id == 4 && x.resume?.Skills?.length > 5 && (
                                    <span className="description-light">+{x.resume?.Skills?.length - 5} more</span>
                                  )}
                                </>
                              ))}
                            </div>
                          </div>
                        )} */}
                        {x.resume?.Skills?.length > 0 && (
                          <div className="d-flex gap-2 align-items-start">
                            <div className="title">
                              Skills <Info text="Self-assessed by applicants" />
                            </div>
                            <div className="description-main">
                              {x?.resume?.Skills?.slice(0, 3)?.map((skill, id) => (
                                <Star key={id} ok={skill?.level[0] === "B" ? 1 : skill?.level[0] === "I" ? 2 : 3} skill={skill?.name} />
                              ))}
                              {!showAllSkills && x.resume?.Skills?.length > 3 && (
                                <span className="description-light" onClick={() => setShowAllSkills(true)} style={{cursor:"pointer"}}>+{x.resume?.Skills?.length - 3} more</span>
                              )}
                              {showAllSkills && x?.resume?.Skills?.slice(3)?.map((skill, id) => (
                                <Star key={id} ok={skill?.level[0] === "B" ? 1 : skill?.level[0] === "I" ? 2 : 3} skill={skill?.name} />
                              ))}
                            </div>
                          </div>
                        )}

                        {/* Cover Letter */}
                        <div className="d-flex gap-2 align-items-start">
                          <div className="title">
                            Coverletter{" "}
                            <Info
                              text={
                                <span>
                                  Cover letter
                                  <br />
                                  Why should you be hired for this role?
                                </span>
                              }
                            />
                          </div>
                          <div className="description-main">
                            <span className="description">
                              <span>
                                {truncate(x?.coverLetter, index)}
                                <span
                                  onClick={() => setShowFull((prev) => prev.map((i, ix) => (ix === index ? !i : i)))}
                                  style={{ color: "#008bdc", cursor: "pointer", marginLeft: "4px" }}
                                >
                                  See {showFull[index] ? "less" : "more"}
                                </span>
                              </span>
                            </span>
                          </div>
                        </div>

                        {/* Availiblity */}
                        <div className="d-flex gap-2 align-items-start">
                          <div className="title">
                            Availiblity{" "}
                            <Info
                              text={
                                <span>
                                  Availiblity
                                  <br />
                                  Are you available for 6 months, starting immediately, for a full-time work from home internship? If not, what is the
                                  time period you are available for and the earliest date you can start this internship on?
                                </span>
                              }
                            />
                          </div>
                          <div className="description-main">
                            <span className="description">
                              {x?.availability
                                ? "Yes, I am available to join Immediately."
                                : `I will be available to join ${x?.specifyAvailability}.`}
                            </span>
                          </div>
                        </div>

                        {/* Task Buttons */}
                        <div className="d-flex align-items-center justify-content-between">
                          <div className="description-main gap-5">
                          <a href={`/review/${x._id}`} target="_blank">
                              View full application
                            </a>
                          {/*  <a href="" className="d-flex gap-2 align-items-center">
                              {" "}
                              <TbNotes /> Add notes
                              </a> */}
                          </div>

                          {!showOptions && params?.type !== "Hired" && (
                            <div className="description-main">
                              {/* <IoChatboxOutline
                                className="chatIconOfemp ml-auto mr-5"
                                style={{ fontSize: '22px', cursor: 'pointer' }}
                                onClick={() => handleChatClick(x.user, state?.employer, state?._id , x._id, navigate)}
                              /> */}
                              {params?.type !== "Ninterested" && (
                                <button
                                  onClick={() => handleUpdateStatus("NotIntrested", index)}
                                  style={{ color: "#fc2d2d", backgroundColor: "#fff2eb" }}
                                >
                                  Not interested
                                </button>
                              )}

                              {params?.type !== "Shortlisted" && params?.type !== "Ninterested" && (
                                <button
                                  onClick={() => handleUpdateStatus("Shortlisted", index)}
                                  style={{ color: "#008bdc", backgroundColor: "#eafcff" }}
                                >
                                  Shortlist
                                </button>
                              )}

                              {params?.type != "Ninterested" ? (
                                <Dropdown1 handleUpdateStatus={handleUpdateStatus} index={index} />
                              ) : (
                                <Dropdown2 handleUpdateStatus={handleUpdateStatus} index={index} />
                              )}
                              <IoChatboxEllipsesOutline
                                className="chatIconOfemp2"
                                style={{ fontSize: '28px', cursor: 'pointer' ,color:'#00a5ec',marginLeft:'3px'}}
                                onClick={() => handleChatClick(x.user, state?.employer, state?._id , x._id, navigate)}
                              />
                            </div>
                          )}
                          
                          {!showOptions && params?.type == "Hired" && (
                            <div className="description-main">
                             {/* <a href="#">Open chat</a>*/}
                             <IoChatboxEllipsesOutline
                                className="chatIconOfemp2"
                                style={{ fontSize: '28px', cursor: 'pointer' ,color:'#00a5ec',marginLeft:'3px'}}
                                onClick={() => handleChatClick(x.user, state?.employer, state?._id , x._id, navigate)}
                              />
                          </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </>
        )}
      </div>

      <SimpleDialog
        candidates={All.filter((items, index) => selectedCandidates[index]).map((i) => i)}
        appliedJobId={state?._id}
        employerId={state?.employer}
        open={openPopup}
        onClose={() => setOpenPopup(false)}
      />
    </>
  );
};

export default ApplicationsAll;

const Circle = () => {
  return <div style={{ width: "4px", height: "4px", background: "#666", borderRadius: "100%" }}></div>;
};

const Star = ({ ok, skill }) => {
  return (
    <div className="d-flex align-items-center gap-3 mr-3">
      {Array.from({ length: ok }, () => (
        <FaStar color="#ffae00" size={14} />
      ))}
      {Array.from({ length: 3 - ok }, () => (
        <CiStar color="#bbb" size={14} />
      ))}

      <span className="description">{skill}</span>
    </div>
  );
};

const Dropdown1 = ({ text, options, handleUpdateStatus, index }) => {
  const dropdownRef = useRef();
  const [dropdown, setDropdown] = useState(false);

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setDropdown(false);
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  const navigate = useNavigate();
  return (
    <>
      <div ref={dropdownRef} style={{ position: "relative" }}>
       {/* <button onClick={() => setDropdown(!dropdown)} style={{ color: "white", backgroundColor: "#00a5ec" }}>
          Next Steps <IoCaretDownSharp style={{ transform: `${dropdown ? "rotate(180deg)" : "rotate(0deg)"}` }} />
        </button>*/}

        {dropdown && (
          <div className="dropdown">
            {/* <span>
              <TbNotes /> Send assignment
            </span>
            <span>
              <LuCalendarClock />
              Schedule Interview
            </span> */}
            <span onClick={() => navigate("/messageEmployer")}>
              <BiMessageSquareDetail /> Start chat
            </span>
            <span onClick={() => handleUpdateStatus("Hired", index)}>
              <BsPersonCheck />
              Hire
            </span>
          </div>
        )}
      </div>
    </>
  );
};

const Dropdown2 = ({ text, options, handleUpdateStatus, index }) => {
  const dropdownRef = useRef();
  const [dropdown, setDropdown] = useState(false);

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setDropdown(false);
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  return (
    <>
      <div ref={dropdownRef} style={{ position: "relative" }}>
        <button onClick={() => setDropdown(!dropdown)} style={{ color: "white", backgroundColor: "#00a5ec" }}>
          Change status to <IoCaretDownSharp style={{ transform: `${dropdown ? "rotate(180deg)" : "rotate(0deg)"}` }} />
        </button>

        {dropdown && (
          <div className="dropdown">
            {/* <span>
              <TbNotes /> Send assignment
            </span>
            <span>
              <LuCalendarClock />
              Schedule Interview
            </span> */}
            <span onClick={() => handleUpdateStatus("Application_Sent", index)}>
              <FaRegFolder /> Application received
            </span>
            <span onClick={() => handleUpdateStatus("Shortlisted", index)}>
              <TiFlashOutline />
              Shortlisted
            </span>
          </div>
        )}
      </div>
    </>
  );
};

const Info = ({ text }) => {
  return (
    <>
      <Tooltip
        content={
          <div
            style={{
              backgroundColor: " black",
              color: "white",
              padding: "10px",
              borderRadius: "4px",
              fontSize: "12px",
              maxWidth: "250px",
              wordBreak: "break-word",
            }}
          >
            {text}
          </div>
        }
      >
        <span style={{ cursor: "pointer" }}>
          <CiCircleQuestion size={15} />
        </span>
      </Tooltip>
      <ToastContainer
      position="top-center"
      autoClose={10000}
      />
    </>
  );
};
